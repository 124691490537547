@import "../../styles/variables.scss";

.hamburger_container {
  width: 3.5rem;
  height: 3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column;
  cursor: pointer;

  .hamburger_lines {
    background-color: rgb(58, 58, 58);
    height: 0.4rem;
    border-radius: 5px;
    background-color: black;
    transition: background-color 0.1s ease-in-out;
  }

  .line1 {
    width: 3rem;
    height: 5px;
  }
  .line2 {
    width: 2.1rem;
    height: 5px;
  }
  .line3 {
    width: 3rem;
    height: 5px;
  }

  @media (min-width: 900px) {
    .hamburger_lines {
      background-color: $SECONDARY;
    }
    .hamburger_container {
      display: none;
    }
  }

  @media (max-width: 900px) {
    .hamburger_lines {
      background-color: $SECONDARY;
    }
  }

  &:hover {
    .hamburger_lines {
      background-color: rgb(225, 225, 225);
    }
  }
}
