@import "../../styles/variables.scss";

.preloader_container {
  width: 100%;
  height: 100vh;
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $SECONDARY;
  //transition: animation 1s ease-in-out;
  // animation: fadeOut 0.6s ease-in-out forwards;
}

.preloader_signature {
  width: 10rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: $PRIMARY;
}

.signature {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

// .fade-out {
//   animation: fadeOut 1s ease-in-out forwards;
// }

// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.5;
//   }
//   100% {
//     opacity: 0;
//     visibility: hidden;
//   }
// }
.loader {
  width: 6rem;
  height: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 100000;
  transform: translateX(4px);
}

.loader__element {
  border-radius: 100%;
  border: 4px solid $PRIMARY;
  margin: 8px;
}

.loader__element:nth-child(1) {
  animation: preloader 0.6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
  animation: preloader 0.6s ease-in-out alternate 0.2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader 0.6s ease-in-out alternate 0.4s infinite;
}

@keyframes preloader {
  100% {
    transform: scale(2);
  }
}
