@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Suranna&display=swap");

.home_container {
  width: 100%;
  max-width: 130rem;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  background-color: $SECONDARY;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.home_introduction_header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 6rem;
}

.home_introduction_container {
  position: relative;
  width: 100%;
  height: 100svh;
  // height: -webkit-fill-available;
  max-width: 130rem;
  // background: url(../../assets/images/Fall.jpg) center/cover;
  // background-attachment: fixed;
}

.home_introduction_image {
  max-height: 100vh;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home_introduction_description {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home_introduction_developer {
  font-size: clamp(2rem, 5vw, 3rem);
  letter-spacing: 3px;
  color: $SECONDARY;
  font-weight: 200;
  text-transform: uppercase;
  font-family: "Suranna", serif;
  line-height: 2.5rem;
  text-align: center;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid rgb(254, 198, 56); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation: typing 3.5s steps(30, end), blink-caret 0.7s step-end infinite;
}

.home_introduction_dive {
  font-size: clamp(1.1rem, 3vw, 1.4rem);
  font-weight: 300;
  letter-spacing: 1px;
  width: 20rem;
  margin-top: 5px;
  color: rgb(254, 198, 56);
  transform: transalteY(-30%);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  -webkit-animation: fade-in-fwd 0.8s ease-in 3.5s both;
  animation: fade-in-fwd 0.7s ease-in 3.5s both;
}

.home_about_container {
  background-color: $SECONDARY;
  max-width: 130rem;
  height: auto;
  width: 90vw;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}

@media (max-width: 800px) {
  .home_about_container {
    flex-direction: column;
    gap: 0;
  }
}

.home_about_item1 {
  height: auto;
  width: auto;
  max-width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 2rem;
  text-align: center;
}

.home_about_title {
  color: $PRIMARY;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 4px;
}

.home_about_description {
  color: $PRIMARY;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 0.7rem;
}

.home_about_button {
  color: $SECONDARY;
  width: 17rem;
  padding: 10px;
  height: 2.5rem;
  margin-top: 1.5rem;
  background-color: $PRIMARY;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: 2px;
  border-radius: 1px;
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: $SECONDARY;
    color: $PRIMARY;
    border: 2px solid $PRIMARY;
  }
}

.fade_in {
  -webkit-animation: fade-in 1.5s ease-in both;
  animation: fade-in 1.5s ease-in both;
}

.follow_container {
  width: 100vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 2rem;
    letter-spacing: 5px;
    color: $PRIMARY;
  }
}

.home_projects_carousel_container_title {
  width: 20rem;
  height: 4rem;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 4px;
  font-weight: 500;
  text-align: center;
  text-shadow: 1px 1px 2px $PRIMARY;
  color: $PRIMARY;
  text-transform: uppercase;
  margin-top: 10px;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(254, 198, 56);
  }
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
