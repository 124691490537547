@import "../../styles/variables.scss";

.projects_carousel_container {
  width: 100%;
  height: fit-content;
  max-width: 130rem;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects_carousel_items {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.projects_carousel_slider {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative !important;
}

.projects_carousel_item {
  width: 21rem !important;
  height: auto !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin: 0 auto !important;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.projects_carousel_item_image_container {
  width: 20rem;
  height: 30%;
  margin-top: 1rem;
}

.projects_carousel_item_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.projects_carousel_item_title {
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 5px;
}

.projects_carousel_item_description {
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.projects_carousel_item_made_with {
  margin: 0 auto;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 600;
}

.projects_carousel_item_button_container {
  width: 20rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.projects_carousel_item_button {
  width: 9rem;
  padding: 10px;
  border-radius: 1px;
  font-size: 1rem;
  font-weight: 500;
  background-color: $PRIMARY;
  color: $SECONDARY;
  transition: all 0.1s ease-in-out;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  &:hover {
    border: 2px solid $PRIMARY;
    color: $PRIMARY;
    background-color: $SECONDARY;
    cursor: pointer;
  }
}

// to style dots
.slick-dots {
  bottom: 2px !important;
  z-index: 999 !important;
}

// to style dots
.slick-dots li button:before {
  font-size: 0.6rem !important;
}

// to style active dot
.slick-dots li.slick-active button:before {
  color: $PRIMARY !important;
}

// //to style arrows
// .slick-arrow {
//   border: 2px solid red !important;
// }

//to style prev arrow
.slick-prev {
  left: 10px !important;
  z-index: 999 !important;
}

//to style next arrow
.slick-next {
  right: 20px !important;
  z-index: 999 !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: black;
}
