@import "../../styles/variables.scss";

.container {
  width: 100%;
  height: 90vh;
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $SECONDARY;
  background-blend-mode: color;
}

.notFound {
  width: 20rem;
  height: 18rem;
  img {
    width: 100%;
  }
}

.go_back_to_home {
  color: $PRIMARY;
  font-size: 1.7rem;
  &:hover {
    color: rgb(134, 134, 134);
  }
}
