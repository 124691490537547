@import "../../styles/variables.scss";

.TodoApp_container {
  background: $SECONDARY;
  width: 100%;
  min-height: 100vh;
  height: auto;
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.TodoApp_name {
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  color: $PRIMARY;
  margin-top: 0.3rem;
  text-shadow: 1px 1px 2px rgb(49, 49, 49);
  letter-spacing: 2px;
}

.Todo_Add_button_container {
  position: relative;
  width: 90%;
  max-width: 40rem;
  height: 3.3rem;
  border-radius: 8px;
  background-color: #ebecf0;
  box-shadow: 7px 8px 19px 0px rgba(0, 0, 0, 0.48);
  margin: 1rem 1rem 0 1rem;
  -webkit-animation: wobble-hor-bottom 1s ease-in-out both;
  animation: wobble-hor-bottom 1s ease-in-out both;

  .Todo_Add_button {
    margin-left: 1rem;
    width: 80%;
    height: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(107, 107, 107);
    background-color: #ebecf0;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .Todo_Add_button_icon {
    position: absolute;
    right: 1rem;
    top: 10px;
    cursor: pointer;
    color: $PRIMARY;
    &:hover {
      transition: 0.1s ease-in;
      color: rgb(107, 107, 107);
      transform: scale(1.3);
    }
  }
}

.Todos_container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
}

//animation wobble
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
