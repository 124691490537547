@import "../../styles/variables.scss";

.contact_me_form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  background-color: $SECONDARY;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
}

.contact_me_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.contact_me_text_area {
  background-color: inherit;
  border-radius: 4px;
  border: 1px solid rgb(198, 197, 197);
  width: 100%;
  padding: 10px;
  color: rgb(28, 28, 28);
  font-size: 1.1rem;
  resize: vertical;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 2px solid $PRIMARY;
  }
}

.contact_me_header {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 2px;
  color: $PRIMARY;
  align-self: flex-start;
}

.contact_me_button {
  width: 11rem;
  height: 3rem;
  border: none;
  font-size: 17px;
  border-radius: 3px;
  color: $SECONDARY;
  background-color: $PRIMARY;
  margin-top: 5px;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 2px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: $SECONDARY;
    color: $PRIMARY;
    border: 2px solid $PRIMARY;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.contact_me_close_button {
  position: absolute;
  top: 1rem;
  right: 8px;
  color: $PRIMARY;
  &:hover {
    cursor: pointer;

    color: rgb(109, 109, 109);
  }
}

@media screen and (max-width: 450px) {
  .contact_me_close_button {
    top: 2rem;
  }
}
