.imageViewer {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.imageViewer {
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    padding: 1rem;
    -webkit-animation: fade-in-bck 0.5s ease-in-out 1;
    animation: fade-in-bck 0.5s ease-in-out 1;
  }
}

.imageViewer_closeicon {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 5px;
  background-color: transparent;
  color: rgb(106, 100, 100);

  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
  &:focus {
    outline-color: transparent;
  }
}

.deleteIcon,
.progress {
  position: fixed;
  top: 60px;
  right: 10px;
  padding: 5px;
  background-color: transparent;
  color: rgb(106, 100, 100);

  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
  &:focus {
    outline-color: transparent;
  }
}

.nextIcon {
  background-color: transparent;
  color: rgb(106, 100, 100);
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;

  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
  &:focus {
    outline-color: transparent;
  }
}

.previousIcon {
  background-color: transparent;
  color: rgb(106, 100, 100);
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;

  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
  &:focus {
    outline-color: transparent;
  }
}

.image_model {
  color: #ffffffb8;
  font-size: 1.3rem;
  font-weight: 500;
  position: absolute;
  bottom: 1rem;
  right: 1.2rem;
  padding: 2px 5px 0px 5px;
  border-radius: 4px;
  background-color: #000000;
}

@media screen and (max-width: 850px) {
  .image_model {
    font-size: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .image_model {
    font-size: 15px;
  }
}

@media screen and (max-width: 420px) {
  .nextIcon {
    right: 0px;
  }
  .previousIcon {
    left: 0px;
  }
}

@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
