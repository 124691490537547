@import "../../styles/variables.scss";

.social_media_Links {
  width: 20rem;
  word-wrap: break-word;
  margin-top: 1.3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.social_media_icons {
  color: rgb(64, 64, 64);
  &:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color: #000000;
    transform: scale(1.2);
  }
}
