@import "../../styles/variables.scss";

.ToDO_card_container {
  background-color: #ebecf0;
  border-radius: 4px;
  width: 20rem;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 7px 8px 19px 0px rgba(0, 0, 0, 0.48);
  position: relative;
}

.dragging-over {
  background-color: #d1d1d1;
}

.ToDO_card_title {
  font-size: 1.6rem;
  font-weight: 600;
  align-self: flex-start;
  padding-left: 1.2rem;
  padding-top: 0.5rem;
  color: $PRIMARY;
}

.text_area {
  background-color: #ffffff;
  border-radius: 4px;
  border: none;
  width: 18.8rem;
  padding: 10px;
  font-weight: 600;
  color: #757575;
  font-size: 1.1rem;
  resize: vertical;
  box-shadow: 7px 8px 16px -11px rgba(0, 0, 0, 0.47);
  &:focus {
    outline: none;
  }
}

.new_card_checker {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(98, 98, 98);
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
  .new_card_save_button {
    background-color: $PRIMARY;
    border: none;
    padding: 0.4rem;
    width: 4.5rem;
    color: #ffffff;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 1px;
    text-transform: uppercase;
    transition: all 0.1s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #ffffff;
      border: 2px solid $PRIMARY;
      color: $PRIMARY;
    }
  }
  .new_card_cancel_button {
    margin-left: 1rem;
  }
  &:hover {
    cursor: pointer;
    color: rgb(78, 78, 78);
  }
}

.add_card_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #757575;
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;

  &:hover {
    cursor: pointer;
    color: rgb(58, 58, 58);
  }
}
