@import "../../styles/variables.scss";

.sideBar_container {
  position: relative;
}

.siderBar_elements {
  width: 100%;
  height: 100%;
  background-color: $SECONDARY;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideBar_closeIcon {
  position: absolute;
  top: 1rem;
  left: 2rem;
  color: $PRIMARY;
  transition: color 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    color: rgb(115, 115, 115);
  }
}

.logout_icon {
  width: 8.5rem;
  display: flex;
  border: none;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  color: rgb(196, 68, 68);
  transform: translateX(-50%);
  &:hover {
    cursor: pointer;
    color: red;
  }
}

.drawer_links_container {
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: rgb(69, 69, 69);
}

.drawer_items {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 2px;
  transition: color 0.1 ease-in-out;
  &:hover {
    cursor: pointer;
    color: rgb(136, 135, 135);
  }
}

.signup_button {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 11rem;
  padding: 10px;
  background: $PRIMARY;
  border: none;
  color: $SECONDARY;
  border-radius: 3px;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 2px;
  transition: all 0.1s ease-in-out;
  &:hover {
    background: $SECONDARY;
    color: $PRIMARY;
    border: 2px solid $PRIMARY;
    cursor: pointer;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
