@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Sono:wght@200;300;400;500;600;700;800&display=swap");

.about_container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  max-width: 130rem;
  margin: 0 auto;
  background-color: $SECONDARY;
}

.about_container_contents {
  width: 100%;
  min-height: 90vh;
  max-width: 130rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.about_quote {
  width: 100%;
  max-width: 130rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Sono", sans-serif;
  position: relative;
}

@media (max-width: 660px) {
  .about_quote {
    padding: 1rem;
    padding-bottom: 2rem;
  }
}

.quote_author {
  position: absolute;
  bottom: 7px;
  right: 0;
  font-size: 1rem;
  padding-right: 5%;
}

.about_container_content1 {
  width: 30rem;
  margin-bottom: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-right: 3rem;
}

.about_container_description {
  color: $PRIMARY;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2rem;
  font-family: "Sono", sans-serif;

  strong {
    &:hover {
      cursor: pointer;
    }
  }
}

.about_container_description_title {
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 10px;
}

.about_container_content2 {
  width: 350px;
  height: 450px;

  --b: 10px; /* control the size */
  padding: var(--b);
  border: calc(2 * var(--b)) solid #0000;
  outline: 1px solid #000;
  outline-offset: calc(-1 * var(--b));
  background: conic-gradient(from 90deg at 1px 1px, #0000 90deg, $PRIMARY 0);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (max-width: 900px) {
  .about_container_contents {
    flex-direction: column;
    justify-content: normal;
    gap: 1rem;
  }
  .about_container_content1 {
    width: 90vw;
    margin-right: 0;
    text-align: center;
  }
  .about_container_content2 {
    margin-bottom: 2rem;
  }
}

.about_follow_container {
  width: 100%;
  max-width: 130rem;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 2rem;
    letter-spacing: 5px;
    color: $PRIMARY;
  }
}

.about_me_animated_name_container {
  width: 20rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 250px;
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 50px;

      .block {
        width: 0%;
        height: inherit;
        background: $PRIMARY;
        position: absolute;
        animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        display: flex;
      }

      h1 {
        color: $PRIMARY;
        font-size: 35px;
        -webkit-animation: mainFadeIn 2s forwards;
        -o-animation: mainFadeIn 2s forwards;
        animation: mainFadeIn 2s forwards;
        animation-delay: 1.6s;
        opacity: 0;
        display: flex;
        align-items: baseline;
        position: relative;

        span {
          width: 0px;
          height: 0px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;

          background: $PRIMARY;
          -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92)
            forwards;
          animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
          animation-delay: 2s;
          margin-left: 5px;
          margin-top: -10px;
          position: absolute;
          bottom: 13px;
          right: -12px;
        }
      }
    }

    .role {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 30px;
      margin-top: -10px;

      .block {
        width: 0%;
        height: inherit;
        background: #ffad28;
        position: absolute;
        animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        animation-delay: 2s;
        display: flex;
      }

      p {
        animation: secFadeIn 2s forwards;
        animation-delay: 3.2s;
        opacity: 0;
        font-weight: 400;
        color: #000000;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 4px;
      }
    }
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #ffad28;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #ffad28;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #ffad28;
    border: 0px solid #222;
    bottom: 13px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
