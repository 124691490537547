@import "../../styles/variables.scss";

.footer_container {
  width: 90%;
  height: 5.5rem;
  max-width: 130rem;
  border-top: 2px solid $PRIMARY;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact_me {
  margin-top: 10px;
  color: $PRIMARY;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin-top: 10px;
  letter-spacing: 1px;
  &:hover {
    cursor: pointer;
    color: #616161;
  }
}

.back_to_top {
  margin-top: 10px;
  color: $PRIMARY;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-weight: 400;
  letter-spacing: 1px;
  &:hover {
    cursor: pointer;
    color: #616161;
  }
}

.back_to_top_icon {
  transform: rotate(90deg);
  // font-size: 3rem;
  // background-color: red;
  &:hover {
    cursor: pointer;
    color: #616161;
  }
}

.shake-vertical {
  -webkit-animation: shake-vertical 1s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
  animation: shake-vertical 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
