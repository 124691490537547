@import "../../styles/variables.scss";

.gallery_container {
  width: 100%;
  max-width: 130rem;
  height: auto;
  min-height: 100vh;
  background-color: $SECONDARY;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.gallery_uploading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.upload_submit_button {
  width: 7.5rem;
  height: 2.2rem;
  border: none;
  background-color: $PRIMARY;
  font-size: 17px;
  font-weight: bold;
  border-radius: 3px;
  color: rgb(241, 241, 241);
  margin-top: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgb(64, 183, 64);
  }
  &:focus {
    outline: none;
  }
}

.gallery_masonry_mui {
  width: 100vw;
  max-width: 130rem;
  min-height: 100vh;
  height: fit-content;
  padding: 10px 0px;
  // row-gap: 0px; /* for gap in row masonry */
  // column-gap: 0px; /* for gap in column masonry */
}

.gallery_masonry_mui_image {
  width: 100%;
  display: block;
  cursor: pointer;
  border-radius: 7px;
  overflow: hidden;
  transition: transform 150ms ease !important;
  &:hover {
    transform: scale(1.03) !important;
  }
}

.gallery_masonry_package_container {
  width: 100vw;
  max-width: 130rem;
  min-height: 100vh;
  height: fit-content;
  overflow: hidden;
}

.gallery_masonry_package_image {
  width: 100%;
  display: block;
  cursor: pointer;
  border-radius: 7px;
  overflow: hidden;
  transition: transform 150ms ease !important;
  &:hover {
    transform: scale(1.01) !important;
  }
}

.gallery_masonry {
  width: 100vw;
  max-width: 130rem;
  min-height: 100vh;
  --webkit-column-count: 3;
  --moz-column-count: 3;
  column-count: 3;
  --webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 1rem;
}

.gallery_masonry_image_container {
  --webkit-transition: all 150ms ease;
  transition: transform 150ms ease;
  cursor: pointer;
  margin-bottom: 12px;

  &:hover {
    // filter: opacity(0.8);
    transform: scale(1.03);
  }
}

.gallery_masonry_image {
  width: 100%;
}

@media screen and (min-width: 1200px) {
  .gallery_masonry {
    --webkit-column-count: 4 !important;
    --moz-column-count: 4 !important;
    column-count: 4 !important;
  }
}

@media screen and (max-width: 1200px) {
  .gallery_masonry {
    --webkit-column-count: 3 !important;
    --moz-column-count: 3 !important;
    column-count: 3 !important;
  }
}

@media screen and (max-width: 900px) {
  .gallery_masonry {
    --webkit-column-count: 2 !important;
    --moz-column-count: 2 !important;
    column-count: 2 !important;
  }
}

@media screen and (max-width: 500px) {
  .gallery_masonry {
    // --webkit-column-count: 1 !important;
    // --moz-column-count: 1 !important;
    // --webkit-column-width: 100% !important;
    // -moz-column-width: 100% !important;
    // column-width: 100% !important;
    // column-count: 1 !important;
    --webkit-column-count: 1 !important;
    --moz-column-count: 1 !important;
    column-count: 1 !important;
  }
}

.skeleton_container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

.skeleton {
  text-align: center;
  padding-top: 174px;
}

.gallery_text_area {
  background-color: inherit;
  border-radius: 4px;
  border: 1px solid rgb(198, 197, 197);
  width: 15.1rem;
  padding: 10px;
  color: rgb(28, 28, 28);
  font-size: 1.1rem;
  resize: vertical;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 2px solid $PRIMARY;
  }
}

.hide_scrollbar {
  scrollbar-width: none; /* hide scrollbar on Firefox */
  -ms-overflow-style: none; /* hide scrollbar on IE10+ */
  overflow-y: scroll; /* add scroll to enable overflow behavior */
}

.hide_scrollbar::-webkit-scrollbar {
  //width: 0px; /* hide scrollbar on Chrome and Safari */
  //height: 0px;
  display: none;
  transition: all 0.4 ease-in-out;
}

@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
