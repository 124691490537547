@import "../../styles/variables.scss";

.projects_container {
  width: 100%;
  max-width: 130rem;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $SECONDARY;
}

.projects_items {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
}

.project_item {
  width: 21rem;
  height: auto;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
}

.project_item_image_container {
  width: 20rem;
  height: 10rem;
  margin-top: 10px;
}

.projects_slider {
  width: 100% !important;
  height: 100% !important;
}

.project_item_image {
  width: 20rem !important;
  height: 10rem !important;
  object-fit: contain !important;
  object-position: center !important;
}

//to style dots
.slick-dots {
  bottom: -10px !important;
  z-index: 999 !important;
}

//to style dots
.slick-dots li button:before {
  font-size: 0.4rem !important;
}

// .slick-prev {
//   left: -4px !important;
//   // z-index: 999 !important;
//   // opacity: 0.7 !important;
// }

// //tp style next arrow
// .slick-next {
//   right: 6px !important;
//   // z-index: 999 !important;
//   // opacity: 0.7 !important;
// }

/* To style dots and decrease the gap between them */
.slick-dots li {
  margin: 0 -4px !important; /* Adjust the margin as needed */
}

.project_item_title {
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 8px;
}

.project_item_description {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.project_item_made_with {
  margin: 0 auto;
  height: fit-content;
  width: 85%;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.project_item_button_container {
  width: 20rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.project_item_button {
  width: 9rem;
  padding: 10px;
  border-radius: 1px;
  font-size: 1rem;
  font-weight: 500;
  background-color: $PRIMARY;
  color: $SECONDARY;
  transition: all 0.1s ease-in-out;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  &:hover {
    border: 2px solid $PRIMARY;
    color: $PRIMARY;
    background-color: $SECONDARY;
    cursor: pointer;
  }
}
