@import "../../styles/variables.scss";

.gallery_carousel_container {
  width: 100vw;
  height: 100vh;
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.gallery_carousel_slider {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: hidden;
  position: relative;
}

.gallery_carousel_image {
  width: 100vw !important;
  height: 100vh !important;
  object-fit: cover !important;
  object-position: center !important;
}

//to style dots
.slick-dots {
  bottom: 5px !important;
  z-index: 999 !important;
}

//to style dots
.slick-dots li button:before {
  font-size: 0.6rem !important;
}

// to style active dot
.slick-dots li.slick-active button:before {
  color: $PRIMARY !important;
}

//to style arrows
// .slick-arrow {
//    border: 2px solid red !important;
// }

//to style prev arrow
.slick-prev {
  left: 10px !important;
  z-index: 999 !important;
  opacity: 0.7 !important;
}

//tp style next arrow
.slick-next {
  right: 10px !important;
  z-index: 999 !important;
  opacity: 0.7 !important;
}

.gallery_carousel_contents {
  position: absolute;
  // top: 50%;
  // left: 1%;
  // transform: translate(0, -50%);
}

.gallery_carousel_title {
  font-size: clamp(3rem, 5vw, 5rem);
  margin-left: 5%;
  color: $PRIMARY;
}

@media (max-width: 300px) {
  .gallery_carousel_navigate {
    width: 11rem;
  }
}

.gallery_carousel_navigate {
  margin-left: 10%;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: rgb(254, 198, 56);
  width: 12.5rem;
  padding: 0 0.1rem;
  font-size: clamp(1rem, 5vw, 1.2rem);
  border-bottom: 2px solid rgb(254, 198, 56);
  .gallery_carousel_navigate_icon {
    transform: rotate(180deg);
  }

  &:hover {
    cursor: pointer;
    color: rgb(230, 179, 51);
    border-bottom: 2px solid rgb(230, 179, 51);
  }
}

// .projects_next_icon_carousel {
//   position: absolute;
//   bottom: 0;
//   right: 5px;
//   font-size: 2rem;
//   &:hover {
//     cursor: pointer;
//   }
// }

// .projects_prev_icon_carousel {
//   position: absolute;
//   bottom: 0;
//   left: 5px;
//   font-size: 10rem;
//   transform: rotate(180deg);
//   &:hover {
//     cursor: pointer;
//   }
// }
