@import "../../styles/variables.scss";

.header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  max-width: 130rem;
  margin: 0 auto;
  background-color: $PRIMARY;
}

.signature_container {
  margin-left: 10%;
  width: 7rem;
  height: 100%;
  &:hover {
    cursor: pointer;
  }

  .sign {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.links_container {
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  div {
    font-size: 1.2rem;
    letter-spacing: 2px;
    color: $SECONDARY;
    transition: color 0.1s ease-in-out;
    &:hover {
      cursor: pointer;
      color: rgb(157, 157, 157);
    }
  }
}

@media (min-width: 900px) {
  .links_container_hamburger {
    display: none;
  }
}

@media (max-width: 900px) {
  .links_container {
    display: none;
  }
  .header_container {
    justify-content: space-between;
    background-color: $PRIMARY;
  }
  .signature_container {
    margin-left: 10%;
  }
  .links_container_hamburger {
    margin-right: 5%;
  }
}

@media (max-width: 400px) {
  .signature_container {
    margin-left: 5%;
  }
}
