@import "../../styles/variables.scss";

.todo_text_cards {
  background-color: #ffffff;
  border-radius: 4px;
  min-height: 3rem;
  word-break: break-word;
  padding: 10px;
  font-weight: 600;
  color: #757575;
  font-size: 1.1rem;
  width: 18.8rem;
  margin-bottom: 3px;
  box-shadow: 7px 8px 16px -11px rgba(0, 0, 0, 0.47);
  position: relative;

  &:hover {
    background-color: rgb(226, 226, 226);
  }

  .show {
    display: inline;
  }

  .hide {
    display: none;
  }

  .card_delete_icon {
    position: absolute;
    top: 8px;
    right: 10px;
    float: right;
    background-color: rgb(226, 226, 226);
    backdrop-filter: blur(10px);
    color: rgb(82, 82, 82);
    &:hover {
      color: rgb(58, 58, 58);
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  .card_edit_icon {
    position: absolute;
    top: 8px;
    right: 38px;
    backdrop-filter: blur(10px);
    width: 40px;
    background-color: rgb(226, 226, 226);
    color: rgb(82, 82, 82);
    &:hover {
      color: rgb(58, 58, 58);
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}

.text_card_text_area {
  background-color: #ffffff;
  border-radius: 4px;
  border: none;
  width: 18.8rem;
  padding: 10px;
  font-weight: 600;
  color: #757575;
  font-size: 1.1rem;
  resize: vertical;
  box-shadow: 7px 8px 16px -11px rgba(0, 0, 0, 0.47);
  &:focus {
    outline: none;
  }
}

.dragging {
  background-color: blue;
}
.text_card_new_card_checker {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(98, 98, 98);
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
  .text_card_new_card_save_button {
    background-color: $PRIMARY;
    border: none;
    padding: 0.4rem;
    width: 4.2rem;
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem;
    border-radius: 1px;
    text-transform: uppercase;
    transition: all 0.1s ease-in-out;
    &:hover {
      background-color: #ffffff;
      border: 2px solid $PRIMARY;
      color: $PRIMARY;
      cursor: pointer;
    }
  }
  .text_card_new_card_cancel_button {
    margin-left: 1rem;
  }
  &:hover {
    cursor: pointer;
    color: rgb(78, 78, 78);
  }
}

.todo_text_card_time {
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 1rem;
  color: #757575;
}

.show {
  display: inline;
}

.hide {
  display: none;
}
